.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.center {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 50%;
}

h1 {
  font-family: 'Roboto Condensed';
  font-style: normal;
  font-weight: 700 !important;
  font-size: 3.5rem !important;
  line-height: 60px;
  text-align: center;
  letter-spacing: 0.1em;
  margin-bottom: 40px !important;

  color: #FFFFFF;

  filter: drop-shadow(8px 8px 5px #4b4b4b);
}

.h2 {
  font-family: Raleway;
  font-style: normal;
  font-weight: 300 !important;
  font-size: 3rem !important;
  text-align: center;
  color: #1D240A;
}

.h3 {
  font-weight: 500 !important;
  font-size: 1.5rem !important;
  text-align: center;
  color: rgb(85, 87, 79);

  padding: 15px 10px 20px;
}

@import url('https://fonts.googleapis.com/css?family=Avenir:300,500');

div p {
  font-family: 'Avenir';
  font-style: normal;
  font-weight: 300;
  line-height: 140.9%;
  color: #1D240A;
}

.intro-greeting {
  font-weight: 500;
  font-style: italic;
  font-size: 2em;
  margin-bottom: 0;
}

.showcase {
  background-image: url('./images/intro-pic.png');
  background-size: cover;
  background-repeat: repeat;
  background-position: 70% center;
  background-attachment: fixed;
  position: relative;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 0 20px;
  animation: transitionIn 1s;
}

.showcase .content {
  animation: transitionInLag 1.8s;
}

@media only screen and (max-width: 1140px) {
  .showcase {
    background-attachment: scroll;
  }
}

@keyframes transitionIn {
  from {
    opacity: 0;
    transform: rotateX(-10deg);
  }

  to {
    opacity: 1;
    transform: rotateX(0);
  }
}

@keyframes transitionInLag {

  0%,
  50% {
    opacity: 0;
    transform: rotateX(-10deg);
  }

  100% {
    opacity: 1;
    transform: rotateX(0);
  }
}

.button-slide {
  font-size: 16px;
  text-decoration: none;
  color: white;
  border: white 2px solid;
  padding: 10px 30px;
  margin-top: 50px;
  z-index: 1;
  transition: all 0.2s ease;
}

.button-slide:hover {
  text-decoration: none;
  color: white;
  cursor: pointer;
  border: white 2px solid;
  background-color: #c7bdac;
  transition: all 0.2s ease-out;
}

.navbar-items {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  background: #2E3C47;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.2em;
  border-bottom: 5px solid;
  border-color: #ebbb6e;
  z-index: 1;
}

.nav-menu {
  display: grid;
  grid-template-columns: repeat(5, auto);
  /* grid-gap: 30px; */
  list-style: none;
  text-align: center;
  width: 70vw;
  justify-content: start;
  padding-inline-end: 40px;
}

.nav-links {
  font-family: 'Avenir';
  font-style: normal;
  font-weight: 300;
  color: white;
  text-decoration: none;
  padding: 1.03rem 2rem;
  transition: all 0.2s ease;
}

.nav-links:hover {
  background-color: #505f6b;
  text-decoration: none;
  cursor: pointer;
  color: white;
  transition: all 0.2s ease-out;
}

.fa-bars {
  color: white;
}

.fa-times {
  color: white;
  transform: translate(-40%, 0%)
}

.menu-icon {
  display: none;
}

@media screen and (min-width: 653px) and (max-width: 991px) {
  .nav-menu {
    justify-content: center;
  }
}

@media screen and (max-width: 652px) {
  .navbar-items {
    position: -webkit-sticky;
    position: sticky relative;
    top: 0;
    border-bottom: 5px solid;
    border-color: #ebbb6e;
  }

  .nav-menu {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 380px;
    grid-gap: 0;
    position: absolute;
    top: 60px;
    left: -100%;
    opacity: 1;
    z-index: 1;
    transition: all 0.5s ease;
  }

  .nav-menu.active {
    background: #3b4b57;
    padding-inline-end: 0;
    padding-inline-start: 0;
    left: 0;
    opacity: 1;
    transition: all 0.5s ease;
    z-index: 1;
  }

  .nav-links {
    text-align: center;
    padding: 1.5rem;
    width: 100%;
    display: table;
  }

  .nav-links:hover {
    background-color: #505f6b;
    border-radius: 0;
  }

  .menu-icon {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(-100%, 35%);
    font-size: 1.5rem;
    cursor: pointer;
  }
}

.image-container {
  position: absolute;
  height: 100%;
  overflow: hidden;
}

.container {
  padding: 60px 20px;
}

.underscore-light {
  display: inline-block;
  position: relative;
  margin: auto;
  width: 40%;
  height: 5px;
  background: #6A9195;
  border-radius: 50px;
}

.underscore-dark {
  display: inline-block;
  position: relative;
  margin: auto;
  width: 40%;
  height: 5px;
  background: #4B6B82;
  border-radius: 50px;
}

.underscore-white {
  display: inline-block;
  position: relative;
  margin: auto;
  width: 40%;
  height: 5px;
  background: white;
  border-radius: 50px;
}

.music-link,
.music-link:visited {
  color: maroon;
}

.music-link:hover {
  text-decoration: none;
  color: rgb(155, 87, 87);
}

.experience {
  display: inline-block;
  position: relative;
  margin: auto;
  padding: 20px;
  width: 100%;
  /* height: 500px; */

  background: #FFFFFF;
  border: 8px solid rgba(103, 62, 88, 0.44);
  box-sizing: border-box;
  box-shadow: 6px 6px 4px #B5A790;
  border-radius: 4px;
}

#logoCol img {
  width: 50px;
  margin-left: 10px;
  margin-right: 10px;
}

p strong {
  font-weight: 500;
}

.container-experience {
  display: flex;
  position: relative;
  margin: auto;
  padding: 20px;
  width: 100%;
  /* height: 500px; */

  background: #FFFFFF;
  border: 8px solid rgba(103, 62, 88, 0.44);
  box-sizing: border-box;
  box-shadow: 6px 6px 4px #B5A790;
  border-radius: 4px;
  -webkit-transition: all 0.25s ease;
  -moz-transition: all 0.25s ease;
  -o-transition: all 0.25s ease;
  -ms-transition: all 0.25s ease;
  transition: all 0.25s ease;
}

.container-experience:hover {
  transform: scale(1.05);
  transition-duration: 0.25s;
}


.img-experience {
  flex: 1;
}

.company-experience {
  flex: 11;
}

#experience-position {
  font-style: italic;
  font-size: 1.1em;
}

@media(min-width: 992px) {
  .container-experience {
    width: 80%
  }
}

@media(min-width: 575px) {
  #experience-dates {
    text-align: right;
  }
}

@media(max-width: 575px) {
  #experience-position {
    margin-bottom: 0;
  }
}

#dots {
  display: block;
  max-width: 8px;
  max-height: 140px;
  padding-top: 10px;
  padding-bottom: 10px;
}

.project {
  position: relative;
  margin: 3px;
  width: 350px;
  height: 300px;
}

.project__image {
  width: 350px;
  height: 300px;
  object-fit: cover;
}

@media(max-width: 350px) {
  .project {
    width: 100vw;
  }

  .project__image {
    width: 100vw;
  }
}

.project__overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(250, 250, 250, 0.8);

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  opacity: 0;
  transition: 0.25s;
}

.project__overlay--blur {
  backdrop-filter: blur(4px);
}

.project__overlay>* {
  transform: translateY(20px);
  transition: transform 0.25s;
}

.project__overlay:hover {
  opacity: 1;
}

.project__overlay:hover>* {
  transform: translateY(0);

}

.project__name {
  font-size: 1.5em;
  font-weight: 500;
}

.project__tools {
  font-weight: 400;
  text-align: center;
  margin-top: 0.25em;
  color: #6A9195;
}

#github-logo:hover {
  filter: brightness(150%);
}

.list__project {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}

.skillset {
  display: flex;
  flex-wrap: wrap;
}

.skill {
  display: inline-flex;
  flex-direction: column;
  align-items: center;

  -webkit-transition: all 0.25s ease;
  -moz-transition: all 0.25s ease;
  -o-transition: all 0.25s ease;
  -ms-transition: all 0.25s ease;
  transition: all 0.25s ease;
}

.skill:hover {
  transform: scale(1.20);
  transition-duration: 0.25s;
}

.skill__image {
  width: 60px;
}

.skill__name {
  text-align: center;
  padding-top: 10px;
}

.col-6-custom {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
}

@media only screen and (min-width: 375px) and (max-width: 575px) {
  .col-6-custom {
    flex: 0 0 33.3333%;
    max-width: 33.3333%;
  }
}

@media only screen and (max-width: 374px) {
  .col-6-custom {
    flex: 0 0 50%;
    max-width: 50%;
  }
}

.contact {
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
  padding: 10px;
}

.contact__image {
  object-fit: contain;
  width: 35px;
  height: 35px;
  margin-right: 20px;
  float: left;
}

.contact__link {
  display: flex;
  align-self: center;
  color: white;
  margin-bottom: 0;
}

#contacts.container {
  padding: 30px 80px;
}

@media only screen and (min-width: 1200px) {
  #contacts.container {
    margin-left: 150px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  #contacts.container {
    margin-left: 100px;
  }
}

@media only screen and (max-width: 451px) {
  #contacts.container {
    padding: 30px 50px;
  }
}

@media only screen and (max-width: 391px) {
  #contacts.container {
    padding: 30px 20px;
  }
}

@media only screen and (max-width: 341px) {
  .contact__link {
    display: none;
  }

  .contact {
    align-items: center;
    justify-content: center;
  }

  .contact__image {
    margin-right: 0;
  }

  .contact.col-custom {
    flex: 0 0 25%;
    max-width: 25%;
  }
}

.contact>a:hover {
  text-decoration: none;
  filter: brightness(70%);
}

.copyright {
  display: flex;
  padding: 50px 0px 100px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.copyright__label {
  text-align: center;
  color: rgb(146, 146, 146);
}